import React from 'react'
import { Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'

class InputField extends React.Component {

    verifyNumeric = (param, { value }) => {
        const { input, isNumeric, maxLength } = this.props
        
        if (!isNumeric) return input.onChange(value)
        
        const regex = /[^0-9.'"#/,$*()\-:;%+ ]/g
        value = value.replace(regex, '')
        const numbers = value.match(/\d/g) || []
        const numberCount = numbers.length
        const isCountOk = numberCount >= parseInt(maxLength)
    
        if (isCountOk) {
            let result = ''
            let digitCount = 0
              
            for (let i = 0; i < value.length; i++) {
                const char = value[i]
                const isDigit = /\d/.test(char)
                const canAddMoreDigits = digitCount < parseInt(maxLength)
                
                result += isDigit ? (canAddMoreDigits ? char : '') : char
            
                isDigit && canAddMoreDigits && digitCount++
            }
              
            value = result
        } 
        return input.onChange(value)
    }
      
    render() {
        const { required, placeholder, description, input, label, type, autoComplete, meta: { touched, error }, translate, id, format } = this.props

        const searchParams = new URLSearchParams(document.location.search)
        const prefillData = searchParams.get(id)

        if(prefillData && input.value === ''){
            input.onChange(prefillData)
        }

        return (
            <Form.Field required={required}>
                <label htmlFor={id}>{label}</label>
                {
                    description && <div className='description'>{description}</div>
                }
                <Form.Input 
                    id={id} 
                    autoComplete={autoComplete} 
                    type={type} {...input} 
                    placeholder={format || placeholder}
                    onChange={this.verifyNumeric}  
                    required={required}
                    error={!!touched && !!error}
                />

                {touched && (error &&
                    <Label pointing basic color='red' id={`error_${label.replace(/ /g, '')}`}>{ error.text ? translate(error.text, { count: error.value }): translate(error)} </Label>)}
            </Form.Field>
        )
    }
}

export default connect(
    state => ({
    }),{}
)(InputField)