import { ADDITIONAL_INFORMATION, CANCEL } from '../constants'

/**
 * Set additionalInformation' data into store.
 *
 * This reducer processes one of defined actions' types:
 * [ADDITIONAL_INFORMATION.COUNTRIES.SUCCESS, ADDITIONAL_INFORMATION.BUSINESS_ENTITY.SUCCESS, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * @function additionalInformation
 *
 * @param {Object} state - Current value of the additionalInformation field of the store (default value is an empty object)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of additionalInformation
 *
 */

const additionalInformation = (state = {}, action) => {
    switch (action.type) {
        case ADDITIONAL_INFORMATION.SIGNED_WAIVERS_LIMIT_REACHED:
            return {
                ...state,
                isWaiversLimitReached: action.payload
            }
        case ADDITIONAL_INFORMATION.COUNTRIES.SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case ADDITIONAL_INFORMATION.BUSINESS_ENTITY.SUCCESS:
            return {
                ...state,
                businessEntity: action.payload
            }
        case CANCEL.SUCCCESS:
            return {}
        default:
            return state
    }
}

export default additionalInformation