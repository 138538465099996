import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormSection, formValueSelector } from 'redux-form'
import CustomField from '../Custom/CustomField'
import { Header, Segment } from 'semantic-ui-react'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

class ParticipantForm extends Component {
    constructor() {
        super()
        this.getFormsList = this.getFormsList.bind(this)
        this.getFormsCustomList = this.getFormsCustomList.bind(this)
    }
    getFormsList() {
        const {
            formFields,
            adultIdentificationType,
            guardianIdentificationType,
            countries,
            signing,
            name,
            idx: minorIdx
        } = this.props
        if (formFields.standard) {
            if (signing === 'myself') {
                return formFields.standard.map((item, idx) =>
                    <CustomField
                        name={name}
                        key={idx}
                        item={item}
                        signing={signing}
                        identificationType={adultIdentificationType}
                        countries={countries}
                    />)
            } else if (signing === 'guardian') {
                return formFields.standard.map((item, idx) =>
                    <CustomField
                        name={name}
                        key={idx}
                        item={item}
                        signing={signing}
                        identificationType={guardianIdentificationType}
                        countries={countries}
                    />)
            } else if (signing === 'minors') {
                return formFields.standard.map((item, idx) =>
                    <CustomField
                        name={name}
                        key={idx}
                        item={item}
                        signing={signing}
                        countries={countries}
                        minorIdx={minorIdx}
                    />)
            }
        }
        return null
    }
    getFormsCustomList() {
        const { formFields, signing, idx: minorIdx } = this.props
        if (formFields.custom) {
            return formFields.custom.map((item, idx) => {
                const myKey = {
                    list: 0,
                    checkbox: 0,
                    text: 0,
                    textArea: 0,
                    numeric: 0
                }

                if(item.fieldType === 'checkbox'){
                    myKey['checkbox']++
                }
                else if(item.fieldType === 'list'){
                    myKey['list']++
                }
                else if(item.fieldType === 'textArea'){
                    myKey['textArea']++
                }
                else if(item.fieldType === 'text'){
                    myKey['text']++
                }
                else if(item.fieldType === 'numeric'){
                    myKey['numeric']++
                }
                return (<CustomField
                    key={idx}
                    idx={myKey[item.fieldType]}
                    item={item}
                    signing={signing}
                    minorIdx={minorIdx}
                />)
            })
        }
        return null
    }
    render() {
        const { formName, signing, idx, languageSettings, waiverLanguage, headerName } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const fieldsList = this.getFormsList()
        const customFieldsList = this.getFormsCustomList()
        return (
            <FormSection name={formName}>
                {/*signing === 'myself' ?
                    <Header as='h2' className="padding-top" id={`participantFormHeader`}>{translate(fields.PARTICIPANT_HEADER)}</Header> :
                    signing === 'minors' ? <Header as='h2' className="padding-top" id={`participantFormHeader`}>{translate(fields.PARENT_HEADER)}</Header> :
                    signing === 'both' ? <Header as='h2' className="padding-top" id={`participantFormHeader`}>{translate(fields.ADULT_HEADER)}</Header> : null */}
                {signing === 'minors' ?
                    <div className="row margin-top">
                        <Segment padded id={`${formName.replace(/ /g, '')}Form`}>
                            <Header dividing as='h2' className="padding-top" id='minorFormHeader'>{translate(fields.MINOR_HEADER, {count: idx + 1})}</Header>
                            {fieldsList}
                            {customFieldsList}
                        </Segment>
                    </div> :
                    <Segment padded id={`${formName.replace(/ /g, '')}Form`}>
                        <Header dividing as='h2' className="padding-top" id={`participantFormHeader`}>{translate(headerName)}</Header>
                        {fieldsList}
                        {customFieldsList}
                    </Segment>
                }
            </FormSection>
        )
    }
}

const selector = formValueSelector('CollectParticipantInfoForm')
export default connect(
    state => {
        const adultIdentificationType = selector(state, `adult.Identification.type`)
        const guardianIdentificationType = selector(state, `guardian.Identification.type`)

        return {
            countries: state.additionalInformation.countries || [],
            adultIdentificationType,
            guardianIdentificationType,
            formFields: state.formFields,
            languageSettings: state.languageSettings,
            waiverLanguage: state.waiverLanguage
        }
    }
)(ParticipantForm)
