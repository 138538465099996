import React, { Component, useState } from 'react'
import { Field } from 'redux-form'
import { Form } from 'semantic-ui-react'
import renderInputField from './inputField'
import BirthDateField from './dateField'
import renderSelectField from './selectField'
import renderTextareaField from './textareaField'
import PhoneField from './phoneField'
import renderCheckboxGroupField from './checkboxGroupField'
import renderCheckboxField from './checkboxField'
import renderStateField from './stateField'
import IdentificationField from './identificationField'
import renderPostalCodeField from './postalCodeField'
import countrySelectField from './countrySelectField'
import { connect } from 'react-redux'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'
import { EmailOptInSettings, SmsOptInSettings } from '../../constants'

class CustomField extends Component {
    constructor(props) {
        super(props)
        this.state = { phoneType: 'Mobile' }
    }
   
    getIdentificationTypeOptions = () => {
        const {languageSettings, waiverLanguage} = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        return [
            {key: '', text: translate(fields.IDENTIFICATION_METHOD), value: ''},
            {key: 'Drivers License', text: translate(fields.DRIVER_LICENCE), value: 'Drivers License'},
            {key: 'Passport', text: translate(fields.PASSPORT), value: 'Passport'},
            {key: 'Social Security', text: translate(fields.SOCIAL_SECURITY), value: 'Social Security'}
        ]
    }

    getGenderOptions = () => {
        const {languageSettings, waiverLanguage} = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        return [
            {key: '', text: translate(fields.SELECT), value: ''},
            {key: 'Male', text: translate(fields.MALE), value: 'MALE'},
            {key: 'Female', text: translate(fields.FEMALE), value: 'FEMALE'},
            {key: 'NotSpecified', text: translate(fields.NOT_SPECIFIED), value: 'NOT_SPECIFIED'}
        ]
    }

    getId(name) {
        const id = name.replace(/ /g, '')
        if (this.props.signing !== 'myself' && this.signing !== 'guardian') {

            const idString = this.props.minorIdx !== undefined ? `minor${this.props.minorIdx}_${id.slice(0, 1).toLowerCase() + id.slice(1)}` : `${id.slice(0, 1).toLowerCase() + id.slice(1)}`
            return idString
        }
        return id.slice(0, 1).toLowerCase() + id.slice(1)
    }

    getPhoneTypeInformation = (value) => {
        this.state.phoneType = value
        this.forceUpdate()
    }

       
    render() {
        const { item, signing, countries, participantOptions, identificationType, businessEntity, languageSettings, waiverLanguage, signingProcess: { kioskMode }, adultAge, name: formName, minorIdx, idx, data} = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const { name, fieldType, possibleValues, description, maxLength, format } = this.props.item
        const emailOptInSettings = this.props.businessEntity?.emailOptInSettings?.waiverSignForm
        const smsOptInSettings = this.props.businessEntity?.smsOptInSettings?.waiverSignForm


        let nameOption
        let autoComplete

        if (kioskMode === undefined) {
            autoComplete = 'on'
        } else {
            autoComplete = JSON.parse(kioskMode) ? 'off' : 'on'
        }

        if (signing === 'myself') {
            nameOption = 'adultOption'
        }
        else if (signing === 'guardian') {
            nameOption = 'guardianOption'
        }
        else {
            nameOption = 'minorOption'
        }
        const required = item[nameOption] === 'required'

        if (item[nameOption] === 'hidden') {
            return (<span></span>)
        }
        else if (name === 'Email') {
            const shouldShowCheckbox = emailOptInSettings ? (signing !== 'minors') && (emailOptInSettings.setting !== EmailOptInSettings.OPTED_IN_AUTO_AND_HIDDEN.key) : (signing !== 'minors')
            const isCheckboxRequired = emailOptInSettings?.setting === EmailOptInSettings.OPTED_IN_REQUIRED.key
            const checkboxValidator = value => !value && isCheckboxRequired ? fields.REQUIRED: ''
            const checkboxLabel = emailOptInSettings?.labels.find(label => label.languageKey === languageSettings.current)
            return (
                <Form.Field>
                    <Field
                        id={this.getId(name)}
                        autoComplete={autoComplete}
                        name={name}
                        type="email"
                        component={renderInputField}
                        label={translate(fields.EMAIL)}
                        required={required}
                        placeholder={signing === 'minors' ? translate(fields.EMAIL_ADDRESS) : translate(fields.EMAIL_SENT_INFORMATION)}
                        translate={translate}
                    />
                    { shouldShowCheckbox  &&
                        <Field
                            id={`sendEmail`}
                            name="sendEmail"
                            component={renderCheckboxField}
                            label={checkboxLabel?.label || translate(fields.EMAIL_UPDATES, {businessName: businessEntity.name})}
                            required={isCheckboxRequired}
                            translate={translate}
                            validate={checkboxValidator}
                        />
                    }
                </Form.Field>
            )
        }
        else if (name === 'Gender') {
            return (
                <Field
                    id={this.getId(name)}
                    name={name}
                    component={renderSelectField}
                    label={translate(fields.GENDER)}
                    required={required}
                    options={this.getGenderOptions()}
                    translate={translate}
                />
            )
        }
        else if (name === 'Identification') {
            return (
                <IdentificationField
                    id={this.getId(name)}
                    autoComplete={autoComplete}
                    name={name}
                    identificationType={identificationType}
                    label={translate(fields.IDENTIFICATION)}
                    placeholder={translate(fields.IDENTIFICATION_METHOD)}
                    identificationTypeOptions={this.getIdentificationTypeOptions()}
                    required={required}
                    translate={translate}
                />
            )
        }
        else if (name === 'Phone') {
            const isMobilePhone = this.state.phoneType === 'Mobile'

            let shouldShowCheckbox = smsOptInSettings ? (signing !== 'minors') && (smsOptInSettings.setting !== SmsOptInSettings.OPTED_IN_AUTO_AND_HIDDEN.key) : (signing !== 'minors')
            shouldShowCheckbox = shouldShowCheckbox && participantOptions.showSMSAcceptance

            const isCheckboxRequired = smsOptInSettings?.setting === SmsOptInSettings.OPTED_IN_REQUIRED.key
            const checkboxValidator = value => !value && isCheckboxRequired ? fields.REQUIRED: ''
            const checkboxLabel = smsOptInSettings?.labels.find(label => label.languageKey === languageSettings.current)
            
            return (
                <Form.Field>
                    <PhoneField
                        id={this.getId(name)}
                        name={name}
                        label={translate(fields.PHONE)}
                        required={required}
                        phoneType={this.getPhoneTypeInformation}
                        translate={translate}
                    />
                    { isMobilePhone && shouldShowCheckbox &&
                        <Field
                            id={`sendSms`}
                            name="sendSms"
                            component={renderCheckboxField}
                            label={checkboxLabel?.label || translate(fields.SMS_UPDATES, {businessName: businessEntity.name})}
                            required={isCheckboxRequired}
                            translate={translate}
                            validate={checkboxValidator}
                        />
                    }
                </Form.Field>
    
            )
            
        }
        else if (name === 'Date of Birth') {
            return (
                <BirthDateField
                    id={this.getId(name)}
                    autoComplete={autoComplete}
                    fieldName={name}
                    formName={formName}
                    label={translate(fields.BIRTH_DATE)}
                    required={required}
                    adultAge={adultAge}
                    signing={signing}
                    minorIdx={minorIdx}
                />
            )
        }
        else if (name === 'Country') {
            return (
                <Field
                    id={this.getId(name)}
                    name={name}
                    component={countrySelectField}
                    label={translate(fields.COUNTRY)}
                    required={required}
                    placeholder={'Select Country'}
                    options={[
                        {key: '', text: translate(fields.SELECT_COUNTRY), value: ''},
                        ...countries.map(country => ({key: country.name, text: country.name, value: JSON.stringify(country)}))
                    ]}
                />
            )
        } else if (name === 'State') {
            return (
                <Field
                    id={this.getId(name)}
                    name={name}
                    component={renderStateField}
                    label={translate(fields.STATE)}
                    required={required}
                    placeholder={translate(fields.SELECT_STATE)}
                />
            )
        } else if (name === 'Postal Code') {
            return (
                <Field
                    id={this.getId(name)}
                    autoComplete={autoComplete}
                    name={name}
                    component={renderPostalCodeField}
                    label={translate(fields.POSTAL_CODE)}
                    required={required}
                />
            )
        }
        else {

            const preparedName = name.replace(/[^a-zA-Z]/g, '\\$&')
            const customFieldName = name.replace(/[^A-Z0-9]+/ig, '-')
            if (fieldType === 'textArea') {
                return (<Field id={`${fieldType}-${signing}${minorIdx !== undefined ? '-' + minorIdx : ''}-${idx}`} name={`customFieldList["${preparedName}"]`}
                    component={renderTextareaField}
                    label={name}
                    required={required}
                    description={description}
                    translate={field => translate(field)}
                />)
            }
            else if (fieldType === 'list') {
                return (
                    <Field
                        id={`${fieldType}-${signing}${minorIdx !== undefined ? '-' + minorIdx : ''}-${idx}`}
                        name={`customFieldList["${preparedName}"]`}
                        component={renderSelectField}
                        label={name}
                        description={description}
                        required={required}
                        translate={field => translate(field)}
                        options={[
                            {key: '', text: 'Select', value: ''},
                            ...possibleValues.map((val, idx) => ({key: idx, text: val, value: val}))
                        ]}
                    />
                )
            }
            else if (fieldType === 'checkbox') {
                return (
                    <Field
                        id={`${fieldType}-${customFieldName}-${signing}${minorIdx !== undefined ? '-' + minorIdx : ''}-${idx}`}
                        name={`customFieldList["${preparedName}"]`}
                        label={name}
                        type="checkbox"
                        component={renderCheckboxGroupField}
                        options={possibleValues}
                        required={required}
                        description={description}
                        translate={field => translate(field)}
                    />
                )
            }
            else if (fieldType === 'text') {
                return (<Field
                    id={`${fieldType}-${signing}${minorIdx !== undefined ? '-' + minorIdx : ''}-${idx}`}
                    autoComplete={autoComplete}
                    name={`customFieldList["${preparedName}"]`}
                    type="text"
                    component={renderInputField}
                    label={name}
                    required={required}
                    description={description}
                    translate={field => translate(field)}
                />)
            }
            else if (fieldType === 'numeric') {
                return (<Field
                    id={`${fieldType}-${signing}${minorIdx !== undefined ? '-' + minorIdx : ''}-${idx}`}
                    autoComplete={autoComplete}
                    name={`customFieldList["${preparedName}"]`}
                    type="text"
                    component={renderInputField}
                    label={name}
                    required={required}
                    description={description}
                    translate={field => translate(field)}
                    maxLength={maxLength}
                    isNumeric={true}  
                    placeholder={format}    
                />)
            }
            else {
                let label = name
                if (name === 'Title') {
                    label = translate(fields.TITLE)
                } else if (name === 'Legal First Name') {
                    label = translate(fields.FIRST_NAME)
                } else if (name === 'Nickname') {
                    label = translate(fields.NICKNAME)
                } else if (name === 'Middle Name') {
                    label = translate(fields.MIDDLE_NAME)
                } else if (name === 'Last Name') {
                    label = translate(fields.LAST_NAME)
                } else if (name === 'Organization') {
                    label = translate(fields.ORGANIZATION)
                } else if (name === 'Street Address') {
                    label = translate(fields.ADDRESS)
                } else if (name === 'City') {
                    label = translate(fields.CITY)
                }

                return (<Field
                    id={this.getId(name)}
                    autoComplete={autoComplete}
                    name={name}
                    type="text"
                    component={renderInputField}
                    label={label}
                    required={required}
                    translate={translate}
                />)
            }
        }
    }
}

export default connect(
    state => ({
        businessEntity: state.additionalInformation.businessEntity,
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage,
        signingProcess: state.signingProcess,
        adultAge: state.participantOptions.adultAge,
        participantOptions: state.participantOptions,
        data: state.data
    }),{}
)(CustomField)
