import React, { Fragment, PureComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import SelectParticipant from './SelectParticipant'
import { Container, Responsive } from 'semantic-ui-react'
import { createAction } from '../sagas/createAction'
import {LANGUAGE, PARTICIPANT_OPTIONS} from '../constants'
import KioskMode from './KioskMode'
import CollectParticipantInfo from './CollectParticipantInfo'
import DocumentsSignature from './DocumentsSignature'
import SigningDocument from './SigningDocument'
import { formValueSelector } from 'redux-form'
import SubscriptionPlanInfo from './SubscriptionPlanInfo'
import RevokeSignedWaiver from './RevokeSignedWaiver'
import DisabledBusinessInfo from './DisabledBusinessInfo'
import AdditionalInformation from './AdditionalInformation'
import UserWay from '../accessibility/userWay'

class Main extends PureComponent {
    componentDidMount() {

        const { location, match: { params: {type, ids, entityId} }, loadParticipantOptions, participantOptions, loadLanguages, isWaiversLimitReached } = this.props
        if (!participantOptions.signingOptions){
            loadParticipantOptions({ location, type, ids, entityId })
        }

        loadLanguages()



    }
    shouldUseKioskMode() {
        const { signingProcess, signing, selectParticipantForm } = this.props

        if (!signingProcess.kioskMode) {
            return false
        }

        if (selectParticipantForm && !signing) {
            return false
        }

        return true
    }

    doAddAccessbility() {

    }

    render() {
        const { participantOptions, match, signingSession, documentForSigning: { isSigned }, isWaiversLimitReached } = this.props

        if ((match.params.type === 'doc' || match.params.type === 'revoke' || match.params.type === 'session') && (!participantOptions || !participantOptions.signingOptions)) {
            return null
        }
        let SwitchRouters
        if (!participantOptions.subscriptionPlan) {
            SwitchRouters = <Switch>
                <Route component={DisabledBusinessInfo} />
            </Switch>
        } else if (match.params.type === 'doc' && (participantOptions || participantOptions.signingOptions) && participantOptions.subscriptionPlan) {
            SwitchRouters = <Switch>
                <Route path={`${match.path}/info/:who/:count/documentsSignature/signing`} component={SigningDocument} />
                <Route path={`${match.path}/info/:who/:count/documentsSignature`} component={DocumentsSignature} />
                <Route path={`${match.path}/info/:who/:count`} component={CollectParticipantInfo} />
                <Route component={SelectParticipant} />
            </Switch>
        } else if (match.params.type === 'session' && Object.keys(signingSession).length && participantOptions.subscriptionPlan) {
            SwitchRouters = <Switch>
                <Route path={`${match.path}/documentsSignature/signing`} component={SigningDocument} />
                <Route path={`${match.path}/documentsSignature`} component={DocumentsSignature} />
                <Route component={CollectParticipantInfo} />
            </Switch>
        } else if (match.params.type === 'revoke' && participantOptions.subscriptionPlan) {
            SwitchRouters = <Switch>
                <Route component={RevokeSignedWaiver} />
            </Switch>
        } else if (!participantOptions.subscriptionPlan) {
            SwitchRouters = <Switch>
                <Route component={SubscriptionPlanInfo} />
            </Switch>
        }

        return (
            <Container id="mainContainer" className="footer-padding">
                {!isSigned ? <AdditionalInformation /> : null}
                { isWaiversLimitReached ? <SubscriptionPlanInfo/> :
                    <Fragment>
                        <Responsive as={'div'} className="ui very padded segment" minWidth={768}>
                            {SwitchRouters}
                            {this.shouldUseKioskMode() && <KioskMode />}
                        </Responsive>
                        <Responsive as={'div'} maxWidth={767}>
                            {SwitchRouters}
                            {this.shouldUseKioskMode() && <KioskMode />}
                        </Responsive>
                    </Fragment>
                }
                {participantOptions.showAccessibilityWidget ? <UserWay/> : null}
            </Container>
        )
    }
}

const selector = formValueSelector('SelectParticipantForm')
export default connect(
    state => ({
        participantOptions: state.participantOptions,
        signingProcess: state.signingProcess || {},
        signingSession: state.signingSession,
        signing: selector(state, 'signing'),
        selectParticipantForm: state.form.SelectParticipantForm,
        hasAccess: (state.additionalInformation.businessEntity || {}).hasAccess,
        documentForSigning: state.documentForSigning,
        businessEntity: state.additionalInformation,
        isWaiversLimitReached: state.additionalInformation.isWaiversLimitReached
    }),
    {
        loadParticipantOptions: createAction(PARTICIPANT_OPTIONS),
        loadLanguages: createAction(LANGUAGE)
    }
)(Main)
