import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Message } from 'semantic-ui-react'
import { SignedWaiversVerification } from '../../constants.js'

class SubscriptionPlanInfo extends PureComponent {

    render() {

        const {  additionalInformation : { isWaiversLimitReached, businessEntity } } = this.props
        const businessName = businessEntity?.name

        const getMessageContent = () => ({
            header: isWaiversLimitReached ?
            SignedWaiversVerification.WAIVERS_LIMIT_REACHED.header : 'Document Not Authorized',
            message: isWaiversLimitReached ?
            SignedWaiversVerification.WAIVERS_LIMIT_REACHED.message.replace('[companyName]', businessName) : 'Business doesn\'t have access to sign new waiver'
        })

        const { header, message } = getMessageContent()

        return (
            <div className="ui padded segment">
                <Message warning>
                    <Message.Header> {header} </Message.Header>
                    <p>{ message }</p>
                </Message>
            </div>
        )
    }
}
// export default SubscriptionPlanInfo
export default connect(
    state => ({
        additionalInformation: state.additionalInformation
    })
)(SubscriptionPlanInfo)
